import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
import TeamMembers from '@interness/web-core/src/components/modules/TeamMembers';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Wrapper mdxType="Wrapper">
      <hr></hr>
      <h1>{`Über uns`}</h1>
      <h2 {...{
        "id": "mitarbeiter"
      }}>{`Mitarbeiter`}</h2>
      <p>{`Mit unseren Goldschmiede- und Uhrmachermeistern sowie unseren Fachverkäuferinnen für Uhren und Schmuck, stehen kompetente und erfahrene Ansprechpartner für Ihre Wünsche rund um das Thema Uhren und Schmuck zur Verfügung.`}</p>
      <p>{`Ob es um den Kauf eines passenden Geschenks für Ihren Partner, jegliche Arten von Reparaturen, die Anfertigung einer Bewertung für ein Erbstück oder anderes geht, wir beraten Sie gerne und gehen auf Ihre Wünsche ein.`}</p>
      <hr></hr>
      <h2 {...{
        "id": "geschaftsfuhrung"
      }}>{`Geschäftsführung`}</h2>
      <TeamMembers listImageSectionWidth={35} mdxType="TeamMembers" />
      <hr></hr>
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      